<template>
  <Cotizacion />
</template>

<script>
import Cotizacion from "../components/Cotizacion";

export default {
  name: "Home",

  components: {
    Cotizacion,
  },
};
</script>
