<template>
  <v-container>
    <v-row class="d-flex justify-end mt-6">
      <v-col cols="12" lg="2">
        <v-img
          :src="require('../assets/logolatammiencarguito.png')"
          contain
          height="120"
      /></v-col>
      <v-col cols="12" lg="7">
        <h5 class="display-1 font-weight-bold mt-3">
          Cotizador LatamShipping - Encomiendas a Colombia
        </h5>
      </v-col>
      <v-col cols="12" xl="2"
        ><v-btn
          color="yellow lighten-1"
          elevation="2"
          x-large
          href="https://latamshipping.com/envios-baratos-colombia"
          target="_blank"
          >Como funciona todo?</v-btn
        ></v-col
      >
    </v-row>
    <v-form ref="form" v-model="valid">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xl="6"
          ><v-card elevation="2" shaped>
            <v-card-title>
              <span class="text-h6" style="word-break: break-word">
                Aquí puedes cotizar de forma Rápida y sencilla el envió de tu
                caja a Colombia. <br />
                <dt>PASOS:</dt>
                <dd class="ml-5">
                  1. Cotiza <br />
                  2. Paga <br />3. Ingresa datos origen y destino <br />4.
                  Entrega tu caja en cualquiera de los 14,000 puntos de UPS.
                  Listo!
                </dd>
              </span>
            </v-card-title>
            <v-alert dense outlined prominent text color="blue" type="success"
              >Paso 1</v-alert
            >
            <v-card-title>
              <span class="text-h6" style="word-break: break-word"
                >Cuentanos cual es la dirección en USA desde donde se va a hacer
                el envío</span
              >
            </v-card-title>
            <v-card-text>
              <v-col cols="12" lg="6">
                <v-select
                  class="ml-3 mr-3"
                  v-model="state"
                  :items="estados"
                  :rules="stateRules"
                  label="Selecciona un estado"
                  single-line
                ></v-select>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  class="ml-3 mr-3"
                  v-model="codigo"
                  :rules="codigoRules"
                  type="number"
                  label="Código Postal"
                  required
                ></v-text-field
              ></v-col> </v-card-text
            ><v-alert dense outlined prominent text color="blue" type="success"
              >Paso 2</v-alert
            ><v-card-title class="bg-cyan-darken-1">
              <span class="text-h6" style="word-break: break-word"
                >Necesitamos el tamaño y peso del envío - Puedes ingresar
                pulgadas o centímetros, libras o kilos. Solo selecciona la
                opción el adecuada.
              </span> </v-card-title
            ><v-card-text>
              <v-row class="ml-3 mr-3">
                <v-col cols="6"
                  ><v-text-field
                    v-model="largo"
                    :rules="medidaRules"
                    label="Largo"
                    type="number"
                    required
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-switch v-model="medida"
                    ><template v-slot:label>
                      <b :class="{ 'primary--text': medida }">{{
                        medida ? "Centimetros" : "Pulgadas"
                      }}</b>
                    </template></v-switch
                  >
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="ancho"
                    :rules="medidaRules"
                    label="Ancho"
                    type="number"
                    required
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-switch v-model="medida"
                    ><template v-slot:label>
                      <b :class="{ 'primary--text': medida }">{{
                        medida ? "Centimetros" : "Pulgadas"
                      }}</b>
                    </template></v-switch
                  >
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="altura"
                    :rules="medidaRules"
                    label="Altura"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-switch v-model="medida"
                    ><template v-slot:label>
                      <b :class="{ 'primary--text': medida }">{{
                        medida ? "Centimetros" : "Pulgadas"
                      }}</b>
                    </template></v-switch
                  >
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="peso"
                    :rules="pesoRules"
                    label="Peso"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-switch v-model="pesos"
                    ><template v-slot:label>
                      <b :class="{ 'primary--text': pesos }">{{
                        pesos ? "Kilogramos" : "Libras"
                      }}</b>
                    </template></v-switch
                  >
                </v-col>
              </v-row>

              <v-row class="ml-3 mr-3">
                <v-col cols="6"
                  ><v-text-field
                    v-model="valorMercancia"
                    :rules="valorRules"
                    label="Valor"
                    type="number"
                    required
                    suffix="USD"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider
            ><v-card-actions class="d-flex justify-end">
              <v-btn
                block
                x-large
                color="primary"
                :loading="loading"
                @click="generarEnvio()"
              >
                Cotizar
              </v-btn>
            </v-card-actions></v-card
          > </v-col
        ><v-col cols="3"></v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="dialogCotizacion"
      width="45%"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogCotizacion = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-card-subtitle>
            <span class="text-h5"> El total de tu envío es el siguiente:</span>
          </v-card-subtitle>
          <table class="table mb-5" width="100%">
            <tbody>
              <tr>
                <th scope="row" class="h5 blue--text text--darken-4 col-9">
                  Flete dentro de USA
                </th>
                <td class="h5 blue--text text--darken-4 col-3">
                  $ {{ fleteUSA }}
                </td>
              </tr>
              <tr>
                <th scope="row" class="h5 blue--text text--darken-4 col-9">
                  Flete internacional desde Florida hasta destino final Colombia
                </th>
                <td class="h5 blue--text text--darken-4 col-3">
                  $ {{ fleteColombia }}
                </td>
              </tr>
              <tr>
                <th scope="row" class="h5 blue--text text--darken-4 col-9">
                  Valor impuestos aduana
                </th>
                <td class="h5 blue--text text--darken-4 col-3">
                  $ {{ valorImpuestos }}
                </td>
              </tr>
              <tr>
                <th scope="row" class="h5 blue--text text--darken-4 col-9">
                  Valor seguro contra robo
                </th>
                <td class="h5 blue--text text--darken-4 col-3">
                  $ {{ valorSeguro }}
                </td>
              </tr>
              <tr>
                <th scope="row" class="h5 blue--text text--darken-4 col-9">
                  Tiempo de entrega
                </th>
                <td class="h5 blue--text text--darken-4 col-3">
                  Aprox {{ tiempoEntrega }} días
                </td>
              </tr>
              <tr>
                <th scope="row" class="h5 blue--text text--darken-4 col-9">
                  Proveedor dentro de USA
                </th>
                <td class="h5 blue--text text--darken-4 col-3">
                  $ {{ proveedor }}
                </td>
              </tr>
              <tr>
                <th scope="row" class="h5 primary col-9 white--text">
                  Total de envío
                </th>
                <td class="h5 primary col-3 white--text">$ {{ totalEnvio }}</td>
              </tr>
            </tbody>
          </table>
          <span style="word-break: break-word">
            <h4>Siguientes pasos:</h4>
            <p>
              Si estás de acuerdo con este valor, realiza el pago
              correspondiente y luego el sistema te pedirá todos los datos del
              remitente y el destinatario. Dos horas después te contactaremos
              vía whats app para enviarte la guía correspondiente para que
              puedas entregar tu caja en cualquier punto de UPS. UPS cuenta con
              más de 14,000 puntos a lo largo del país. Cualquier duda dale
              Contáctanos a nuestra línea única de Whatsapp.
            </p>
          </span>
          <v-divider></v-divider>
          <v-card-actions class="">
            <v-row
              ><v-col cols="12" md="6">
                <v-btn color="success" :loading="loading" @click="pagar()">
                  Pagar ya con tarjeta de crédito/debito
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn color="primary" @click="dialogCotizacion = false">
                  Cotizar otro envío
                </v-btn> </v-col
              ><v-col cols="12" md="6">
                <v-btn
                  color="purple lighten-3"
                  href="https://web.whatsapp.com/send?phone=17542650061&text=Quiero pagar con Zelle"
                  target="_blank"
                >
                  Pagar con Zelle
                  <img :src="require('../assets/zelle.png')" />
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn color="teal lighten-2" @click="email = !email">
                  Enviar cotización por email
                  <v-icon>mdi-email</v-icon>
                </v-btn>
                <v-text-field
                  v-if="email"
                  v-model="emailData"
                  label="E-mail"
                  required
                ></v-text-field
                ><v-btn v-if="email" @click="sendCorreo()" :loading="loading"
                  >Send <v-icon>mdi-send</v-icon></v-btn
                >
              </v-col></v-row
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        :pk="publishableKey"
        mode="payment"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (loading = v)"
      />
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
//import numeral from "numeral";
import Swal from "sweetalert2";
export default {
  name: "Cotizacion",
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    this.productKey = process.env.VUE_APP_MIX_STRIPE_PROD_KEY;
    this.successURL = process.env.VUE_APP_SUCCESS_URL;
    this.cancelURL = process.env.VUE_APP_CANCEL_URL;
    return {
      valid: false,
      medidaRules: [(v) => !!v || "Medida is required"],
      pesoRules: [(v) => !!v || "Peso is required"],
      codigoRules: [(v) => !!v || "Codigo Postal is required"],
      stateRules: [(v) => !!v || "State is required"],
      valorRules: [(v) => !!v || "Valor is required"],

      state: null,
      codigo: null,

      largo: null,
      ancho: null,
      altura: null,
      peso: null,
      valorMercancia: null,
      selectShip: "",
      arrayShips: [],
      dialogCotizacion: false,
      pesos: false,
      medida: false,
      fleteUSA: null,
      fleteColombia: null,
      valorImpuestos: 0,
      valorSeguro: null,
      tiempoEntrega: null,
      proveedor: "",
      object_id: "",
      totalEnvio: null,
      loading: false,

      email: false,
      emailData: "",

      lineItems: [
        {
          price: "", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],

      estados: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
    };
  },
  methods: {
    generarEnvio() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        axios
          .post("https://app.latamshipping.com/api/sinRegistroNuevo", {
            state: this.state,
            zip: this.codigo,

            profundidad: this.largo,
            ancho: this.ancho,
            altura: this.altura,
            peso: this.peso,
            valorMercancia: this.valorMercancia,
            massUnit: this.pesos ? "kg" : "lb",
            distanceUnit: this.medida ? "cm" : "in",
          })
          .then((response) => {
            if (response.status == 200) {
              this.fleteUSA = response.data.fleteUSA;
              this.fleteColombia = response.data.fleteColombia;
              this.valorImpuestos = response.data.valorImpuestos;
              this.valorSeguro = response.data.valorSeguro;
              this.fleteUSA = response.data.fleteUSA;
              this.tiempoEntrega = response.data.tiempoEntrega;
              this.proveedor = response.data.proveedor;
              this.totalEnvio = response.data.totalEnvio;
              this.dialogCotizacion = true;
              this.loading = false;
            } else {
              const error = response.data.messages.find(
                (sender) => sender.source === "UPS"
              );
              Swal.fire(
                "Ups!",
                error.text ? error.text : "Los datos del envio son incorrectos",
                "error"
              );
              this.loading = false;
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Ups!", "error" + error.response.data.message, "error");
            this.dialogCotizacion = false;
            this.loading = false;
          });
      }
    },

    pagar() {
      try {
        let me = this;
        me.loading = true;
        axios
          .post("https://app.latamshipping.com/api/createPrice", {
            product: me.productKey,
            amount: me.totalEnvio.replace(/\./g, ""),
          })
          .then(function (response) {
            me.lineItems[0].price = response.data.trim();
            if (me.lineItems[0].price) {
              if (response.status == 200) {
                me.$refs.checkoutRef.redirectToCheckout();
                this.loading = false;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            me.loading = false;
          });
        me.loading = false;
      } catch (e) {
        console.error(e);
        Swal.fire(
          "¡Ups!",
          "No se pudo completar la solicitud, revise que todos los datos esten correctos",
          "error"
        );
        this.loading = false;
      }
    },

    sendCorreo() {
      this.loading = true;
      axios
        .post("https://app.latamshipping.com/api/sendCotizacion", {
          email: this.emailData,
          state: this.state,
          zip: this.codigo,
          valorMercancia: this.valorMercancia,
          peso: this.peso,
          fleteUSA: this.fleteUSA,
          fleteColombia: this.fleteColombia,
          valorImpuestos: this.valorImpuestos,
          valorSeguro: this.valorSeguro,
          tiempoEntrega: this.tiempoEntrega,
          proveedor: this.proveedor,
          totalEnvio: this.totalEnvio,
        })
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              title: "Enviada!",
              text: "Cotizacion Enviada",
              icon: "success",
            });
            this.loading = false;
          } else {
            Swal.fire("Ups!", "Los datos del envio son incorrectos", "error");
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire("Ups!", "error" + "Los datos son incorrectos", "error");
          this.loading = false;
        });
    },
  },
};
</script>
