<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo-airbill.png')"
          transition="scale-transition"
          width="40"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn href="https://app.latamshipping.com/login" target="_blank" text>
        <span class="mr-2">Iniciar sesión </span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
      <v-btn
        fab
        dark
        large
        color="success"
        fixed
        right
        bottom
        href="https://web.whatsapp.com/send?phone=17542650061&text="
        target="_blank"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
